import { get, put, del, post } from '../index'

/**
 * 获取租户参数配置
 */
export const getTntConfig = (code) => {
  return get(`/system/config/tnt/${code}`).then(res => res)
}

export const listTntConfig = (params) => {
  return get("/system/config/tnt/list", params).then(res => res)
}

/**
 * 保存配置
 */
export const saveOrUpdateConfig = (data) => {
  return put('/system/config/tnt', data).then(res => res)
}

export const saveBatch = data => {
  return post("/system/config/insert/batch", data).then(res => res);
}

/**
 * 获取企业个人参数配置
 */
export const getOrgOwnConfig = (param) => {
  return get('/system/config/org/own', param).then(res => res)
}

/**
 * 设置企业个人参数配置
 */
export const setOrgOwnConfig = (data) => {
  return put('/system/config/org/own', data).then(res => res)
}

/**
 * 删除企业个人参数配置
 */
export const delOrgOwnConfig = (data) => {
  return del('/system/config/org/own', data).then(res => res)
}

/**
 * 设置企业个人参数配置
 */
export const setOwnConfig = (data) => {
  return put('/system/config/own', data).then(res => res)
}

/**
 * 删除企业个人参数配置
 */
export const delOwnConfig = (data) => {
  return del('/system/config/own', data).then(res => res)
}

export const getOrgOwn = (data) => {
  return get('/system/config/org/own', data).then((res) => res);
};

export const putOrgOwn = (data) => {
  return put('/system/config/org/own', data).then((res) => res);
};
