export default {
  //收票中心 金额校验
  Amount: /^([-+])?\d+(\.[0-9]{1,2})?$/,
  //发票代码
  InvoiceCode: /^\d{10}$|^\d{12}$/,
  //发票号码
  InvoiceNo: /\d{8}/,
  //数电票号
  EInvoiceNo: /\d{20}/,
  //收票中心 校验码
  InvoiceVerifyCode: /^\d{6}$/,
  //设备管理 新增、编辑税盘 税盘密码
  EquipmentPassword: /^[a-zA-Z0-9]{6,16}$/,
  //设备管理 新增、编辑税盘 助手密码
  EquipmentAgentPassword: /^[a-zA-Z0-9]{8,20}$/,
  //设备管理 新增、编辑税盘 收款人、复核人、开票人
  EquipmentPeopleName: /^[a-zA-Z0-9\u4e00-\u9fa5]{1,10}$/,
  //设备管理 新增、编辑税盘 设备别名
  EquipmentAlias: /^[a-zA-Z0-9\u4e00-\u9fa5]{2,10}$/,
  // 邮箱地址
  Email: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
  //用户管理 密码
  Password: /^[A-Za-z0-9\-_@!\$#.]{4,30}$/,
  // 1.注册中心 2.修改密码  手机号
  Phone: /^1[3456789]\d{9}$/,
  //登录中心 忘记密码 注册中心 手机验证码
  SMSCheckCode: /^\d{6}$/,
  //税号
  TaxNo: /^[A-Z0-9]{15}$|^[A-Z0-9]{17}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}/g,
  //1.开票管理 发票开具 手工开票 联系电话（纸票邮寄） 2.组织管理 创建组织 电话校验
  Telephone: /^[\S]{1,32}$/,
  //用户管理 用户账号
  Username: /^[A-Za-z0-9\-_@.]{4,20}$/,
  //供应商名称
  SupplierName: /^[`~!@#$%^&*_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*——\-+={}|《》？：“”【】、；‘’，。、]/g,
  //总页数
  PageSize: /\d/,
  //当前页数
  PageIndex: /\d/,
  //正负整数
  Integer: /^(-)?[0-9]*$/,
  EquipmentExtensionNo: /^[A-Z0-9]{0,20}[\-]?[A-Za-z0-9]{1,20}$/
};
