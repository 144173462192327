<template>
  <div class="msg_cmps" v-show="isShow">
    <div><i class="el-icon-info" color="#f40"></i>&nbsp;&nbsp;打开【自动查验】，修改发票信息将会再次查验发票</div>
    <div><i class="el-icon-circle-close" size="20" @click="closeTips"></i></div>
  </div>
</template>

<script>
const Time = 30;
export default {
  components: {},
  data() {
    return {
      isShow: true
    };
  },
  created() {
    this.isShow = this.$cookie.get('is-show-msg') == Time ? false : true;
  },
  methods: {
    closeTips() {
      this.$cookie.set('is-show-msg', Time, { expires: Time, path: '/' });
      this.isShow = false;
    }
  }
};
</script>

<style scoped lang="scss">
.msg_cmps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  border-radius: 2px;
  background: #e6f7ff;
  border: 1px solid #91d5ff;
  margin-bottom: 20px;
  text-align: left;
  box-sizing: border-box;
  padding: 0 16px;
  .el-icon-info {
    color: #1890ff;
  }
  .el-icon-circle-close {
    font-size: 20px;
    cursor: pointer;
  }
}
</style>
